<template>
    <svg
        width="19"
        height="20"
        viewBox="0 0 19 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M18 9.43839L9.96279 17.4644C8.97817 18.4476 7.64274 19 6.25028 19C4.85782 19 3.52239 18.4476 2.53777 17.4644C1.55315 16.4811 1 15.1476 1 13.7571C1 12.3665 1.55315 11.033 2.53777 10.0497L10.575 2.02375C11.2314 1.36825 12.1217 1 13.05 1C13.9783 1 14.8686 1.36825 15.525 2.02375C16.1814 2.67924 16.5502 3.56829 16.5502 4.4953C16.5502 5.42231 16.1814 6.31135 15.525 6.96685L7.47904 14.9928C7.15083 15.3206 6.70569 15.5047 6.24154 15.5047C5.77738 15.5047 5.33224 15.3206 5.00403 14.9928C4.67583 14.6651 4.49144 14.2206 4.49144 13.7571C4.49144 13.2935 4.67583 12.849 5.00403 12.5213L12.429 5.11537"
            stroke="#FF690C"
            stroke-width="1.8"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    name: 'PaperClip'
}
</script>
<style scoped lang='scss'>
svg {
    margin-right: 10px;
}
svg path {
    stroke: $main-color;
}
</style>
