<template>
    <div class="crypto_wrapper">
        <router-link
            :to="{ name: 'support_form', params: {} }"
            class="crypto_support-center"
        >
            Please use our help center to provide you with more accurate payment system information manually by our administrator.
        </router-link>
        <hint-info
            text="The Bitfinex gateway accepts many cryptocurrencies. Below you can see the approximate prices"
        />
        <div
            v-if="getterBitfinexInvices.length"
            class="crypto"
        >
            <div
                v-for="(item, index) in getterBitfinexInvices"
                :key="index"
                class="crypto_item"
            >
                <img
                    :src="require(`@/assets/img/crypto/${item.currency}.svg`)"
                    :alt="item.currency"
                >
                <p>
                    {{ item.amount }} {{ item.currency }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { eventBus } from '@/helpers/event-bus/'
import { createNamespacedHelpers, mapGetters } from 'vuex'
import {
    ACTION_BITFINEX_PAYMENT
} from '@/store/modules/checkout/action-types.js'
import HintInfo from '@/components/common/Hint'

const {
    mapActions: mapCheckoutActions
} = createNamespacedHelpers('checkout')

export default {
    components: {
        HintInfo
    },
    data() {
        return {
            isLoading: true
        }
    },
    computed: {
        ...mapGetters('checkout', [
            'getterBitfinexInvices'
        ])
    },
    async created() {
        try {
            this.isLoading = true
            this.$emit('bitfinexLoading', this.isLoading)
            await this[ACTION_BITFINEX_PAYMENT]({ batchid: this.$route.params.batchid })
        } catch (error) {
            eventBus.$emit('showSnackBar', error, 'error')
        } finally {
            this.isLoading = false
            this.$emit('bitfinexLoading', this.isLoading)
        }
    },
    methods: {
        ...mapCheckoutActions([
            ACTION_BITFINEX_PAYMENT
        ])
    }
}
</script>

<style lang="scss" scoped>
    .crypto{
        display: flex;
        flex-wrap: wrap;
        margin-left: -20px;
        margin-right: -20px;
        max-width: 80%;
        @media (max-width: 768px) {
            max-width: 100%;
        }
        &_wrapper{
            display: flex;
            align-items: center;
            flex-direction: column;
        }
        &_support-center{
            display: flex;
            justify-content: center;
            padding: 20px 40px;
            color: black;
            cursor: pointer;
            width: 100%;
            background: #F8F8F8;
            border-radius: 4px;
            text-align: center;
            font-weight: 500;
            &:hover{
                color: $main-color;
                background-color: rgba($main-color, 0.1);
            }
            @media (max-width: 768px) {
                width: 100%;
            }
        }
        &_item {
            width: calc(25% - 40px);
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 10px 20px;
            @media (max-width: 768px) {
                width: calc(50% - 40px);
            }

            img {
                max-width: 100%;
                width: 40px;
                max-height: 40px;
                height: 40px;
                margin-bottom: 10px;
            }
            & > p {
                font-weight: 700;
                font-size: 14px;
                white-space: nowrap;
            }
        }
    }
</style>
