import {
    maxLength,
    minLength,
    required
} from 'vuelidate/lib/validators';

import moment from 'moment'

export const validationRules = {
    cc_number: {
        required,
        isCorrectType() {
            if (this.paymethod === 'cc') {
                return this.cc_type === 'visa' || this.cc_type === 'mastercard';
            }
            return true
        }
    },
    cc_name: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(100)
    },
    cc_address: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(100)
    },
    cc_zip: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(100)
    },
    cc_expiry: {
        required,
        minLength: minLength(4),
        checkDate(value) {
            let creditCardDate = null
            const today = moment();
            const MONTH = value.substring(0, 2);
            const YEAR = value.substring(2);
            creditCardDate = moment(YEAR + MONTH, 'YYMM');
            return creditCardDate.isValid() && (today < creditCardDate.add(1, 'months'));
        }
    },
    cc_cvv: {
        checkCVV(value) {
            return value.length === 3 || value.length === 4
        }
    }
}
