<template>
    <div class="order-form wrapper">
        <breadcrumbs
            :settings="settingsBreadcrumbs"
            back-btn-path="/"
        />
        <RadiusLoader v-if="isLoadingCart" />
        <div
            v-if="getterCart.length && !isLoadingCart"
            class="row checkout-row"
        >
            <!-- left column -->
            <div class="order-form__left-column">
                <projects-in-cart
                    :orders="getterCart"
                    @eventDeleteOrder="onEventDeleteOrder"
                    @eventEditOrder="onEventEditOrder"
                />
            </div>
            <div class="order-form__right-column">
                <div class="sticky">
                    <checkout-sidebar
                        :payment="getterPayment"
                        :create-batch-loading="createBatchLoading"
                        @eventPay="onEventPay"
                    />
                </div>
            </div>
            <!-- right column - quote -->
        </div>
        <!-- empty cart shows here -->
        <section v-if="getterCart.length === 0 && !isLoadingCart">
            <div class="empty-cart">
                <header class="empty-cart__header">
                    <h1 class="empty-cart__title">
                        <Cart />
                        Orders
                    </h1>
                </header>
                <div class="empty-cart__body">
                    <h1 class="empty-cart__body-title">
                        Your cart is empty !
                    </h1>
                    <div class="empty-cart__btn">
                        <router-link
                            class="btn-base btn-border"
                            :to="{ name: 'support_form' }"
                        >
                            Contact us
                        </router-link>

                        <button
                            class="btn-base btn-main"
                            @click="createNewOrder"
                        >
                            New order
                        </button>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Cart from 'mdi-vue/Cart'
import { createNamespacedHelpers, mapGetters } from 'vuex'
import { eventBus } from '@/helpers/event-bus/'

// page components
import RadiusLoader from '@/components/common/RadiusLoader'
import ProjectsInCart from '@/components/checkout/ProjectsInCart.vue'
import CheckoutSidebar from '@/components/checkout/CheckoutSidebar.vue'
import Breadcrumbs from '@/components/Breadcrumbs';

// types
import {
    RESET
} from '@/store/modules/checkout/mutation-types.js'

import {
    ACTION_CREATE_BATCH,
    ACTION_GET_CART_FROM_BACKEND,
    ACTION_DELETE_ORDER
} from '@/store/modules/checkout/action-types.js'

// vuex
import {
    CLEAR_FORM_STANDARD
} from '@/store/modules/order/mutation-types'

const {
    mapMutations: mapOrderMutations
} = createNamespacedHelpers('order')

const {
    mapMutations: mapCheckoutMutations,
    mapActions: mapCheckoutActions
} = createNamespacedHelpers('checkout')

export default {
    name: 'Checkout',
    components: {
        RadiusLoader,
        ProjectsInCart,
        CheckoutSidebar,
        Cart,
        Breadcrumbs
    },
    metaInfo: {
        title: 'Checkout'
    },
    data() {
        return {
            isLoadingCart: true,
            createBatchLoading: false
        }
    },
    computed: {
        ...mapGetters('checkout', [
            'getterCart',
            'getterPayment'
        ]),
        settingsBreadcrumbs() {
            const { breadcrumbs } = this.$route.meta
            return {
                title: breadcrumbs.name,
                excerpt: breadcrumbs.excerpt,
                additionalClass: breadcrumbs.additionalClass
            }
        }
    },
    async created() {
        this.isLoadingCart = true
        await this[ACTION_GET_CART_FROM_BACKEND]()
        this.isLoadingCart = false
    },
    methods: {
        ...mapOrderMutations([
            CLEAR_FORM_STANDARD
        ]),
        ...mapCheckoutMutations([
            RESET
        ]),
        ...mapCheckoutActions([
            ACTION_CREATE_BATCH,
            ACTION_GET_CART_FROM_BACKEND,
            ACTION_DELETE_ORDER
        ]),
        async onEventDeleteOrder(orderid) {
            const modalTitle = 'Delete Order'
            const modalText = `You are about to delete order ${orderid}. Are you sure?`
            if (await this.$root.$confirm(modalTitle, modalText, { color: 'red' })) {
                try {
                    await this[ACTION_DELETE_ORDER]({ orderid })
                } catch (error) {
                    eventBus.$emit('showSnackBar', error, 'error')
                }
            }
        },
        async onEventEditOrder(orderid) {
            const modalTitle = 'Edit Order'
            const modalText = `You are about to edit order ${orderid}. Are you sure?`
            if (await this.$root.$confirm(modalTitle, modalText, { color: 'red' })) {
                this.$router.push({ name: 'order_edit', params: { orderid } })
            }
        },
        async onEventPay() {
            try {
                this.createBatchLoading = true
                const batch = await this[ACTION_CREATE_BATCH]()
                if (batch.status === 'UNPAID') {
                    this.$router.push({ name: 'checkout-batch', params: { batchid: batch.batchid } })
                } else {
                    this.$router.push({ name: 'checkout_success' })
                }
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.createBatchLoading = false
            }
        },
        createNewOrder() {
            this[CLEAR_FORM_STANDARD]()
            this.$router.push({ name: 'order' })
        }
    }
}
</script>
