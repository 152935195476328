<template>
    <div class="quote-sidebar">
        <div class="gradient-border" />

        <header class="quote-sidebar__header">
            <div class="row space-between">
                <h2>Checkout here</h2>
            </div>
        </header>
        <section>
            <div
                v-if="getterMainClientInfo.account_balance > 0"
                class="quote-sidebar__prices"
            >
                <div class="quote-sidebar__full">
                    <span class="quote-sidebar__full-name">Grand total: </span>
                    <span class="quote-sidebar__full-value">
                        <animated-number
                            class="quote-sidebar__full-amount"
                            :value="getterPayment.total"
                            :duration="300"
                            :format-value="formatToPrice"
                        />
                    </span>
                </div>

                <!-- store credit -->
                <div class="quote-sidebar__balance">
                    <span class="quote-sidebar__balance-name">
                        <strong>Credit</strong>
                    </span>
                    <span class="quote-sidebar__balance-value">
                        <animated-number
                            class="quote-sidebar__balance-amount"
                            :value="getterPayment.total_store_credit"
                            :duration="300"
                            :format-value="formatToPrice"
                        />
                    </span>
                </div>
            </div>
            <!-- Total-->
            <div class="quote-sidebar__data">
                <span class="quote-sidebar__data-name">Net payable: </span>
                <span class="quote-sidebar__data-value">
                    <animated-number
                        class="quote-sidebar__total-amount"
                        :value="getterPayment.payable"
                        :duration="300"
                        :format-value="formatToPrice"
                    />
                </span>
            </div>
        </section>
        <footer>
            <div class="quote-sidebar__row">
                <form-checkbox
                    v-model="$v.chk_agreement.$model"
                    class="order-form-step__checkbox"
                    :errors="agreementErrors"
                />
                <span
                    class="term-service"
                    :class="shake && 'term-service--shake'"
                >
                    I agree with the <span @click="openAgreementModal()">terms of service</span>
                </span>
                <p class="agreement-errors">
                    {{ agreementErrors }}
                </p>
            </div>
            <div
                class="quote-sidebar__row"
            >
                <span
                    v-if="getterPayment.cartInvalid"
                    class="cart_error"
                >
                    <AlertOutlineIcon class="quote-sidebar__icon" />
                    <b>Fix the invalid orders in the cart to proceed</b>
                </span>
                <custom-button
                    id="btnPay"
                    class="btn-base btn-main quote-sidebar__btn"
                    :disabled="getterPayment.isLoading || getterPayment.cartInvalid || createBatchLoading"
                    :loading="getterPayment.isLoading || createBatchLoading"
                    :loading-message="'Processing...'"
                    :loader-animate="true"
                    @on-btn-click="submit"
                >
                    Proceed
                </custom-button>
            </div>
            <div
                v-if="getterLoadingStatus"
                class="quote-sidebar__row"
            >
                <div class="quote-sidebar__loader">
                    <ButtonLoader />
                    <span>
                        {{ getterLoadingMessage }}
                    </span>
                </div>
            </div>
        </footer>
        <agreement-modal
            ref="agreement-modal"
            @terms-agree="confirm"
        />
    </div>
</template>

<script>
// validation
import { validationMixin } from 'vuelidate';

// components
import AnimatedNumber from 'animated-number-vue'
import { mapGetters } from 'vuex'
import AgreementModal from '@/components/modals/AgreementModal'
import ButtonLoader from '@/components/common/ButtonLoader';
import AlertOutlineIcon from 'mdi-vue/AlertOutline.vue'

export default {
    name: 'CheckoutSidebar',
    components: {
        AnimatedNumber,
        AgreementModal,
        ButtonLoader,
        AlertOutlineIcon
    },
    mixins: [
        validationMixin
    ],
    props: {
        createBatchLoading: {
            type: Boolean,
            default: false
        }

    },
    validations: {
        chk_agreement: {
            checkIfAgreed() {
                return this.chk_agreement
            }
        }
    },
    data() {
        return {
            chk_agreement: false,
            shake: false,
            payLoadingMessage: ''
        }
    },

    computed: {
        ...mapGetters('checkout', [
            'getterValidationState',
            'getterLoadingStatus',
            'getterLoadingMessage',
            'getterPayment'
        ]),
        ...mapGetters('client', [
            'getterMainClientInfo'
        ]),
        agreementErrors() {
            const errors = []
            if (!this.$v.chk_agreement.$dirty) {
                return errors.join(' ')
            }
            !this.$v.chk_agreement.checkIfAgreed && errors.push('You must read and accept the terms of service')
            return errors.join(' ')
        }
    },
    watch: {
        agreementErrors() {
            if (this.agreementErrors.length) {
                this.shake = true
                setTimeout(() => {
                    this.shake = false
                }, 500);
            }
        }
    },
    methods: {
        formatToPrice(value) {
            return `$ ${value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
        },
        openAgreementModal() {
            this.$refs['agreement-modal'].open()
        },
        submit() {
            this.$v.$touch()
            if (!this.$v.$invalid) {
                this.$emit('eventPay')
            }
        },
        confirm() {
            this.chk_agreement = true
        },
        validateCheckoutForm() {
            this.$emit('validateCheckoutForm')
        }
    }
}
</script>

<style lang="scss">
    .quote-sidebar {
        position: sticky;
        top: 100px;
        &__data {
            align-items: center;
            padding: 0 20px 10px;
            padding-bottom: 0;
        }
        &__row {
            .term-service {
                span {
                    color: $main-color;
                    cursor: pointer;
                }
            }
        }
        .report--checkout{
            margin-right: 0;
        }
        .cart_error{
            padding-bottom: 20px;
            line-height: 1.3;
            color: $error-color;
        }
    }

    @media (max-width: 1200px) {
        .quote-sidebar {
            top: 80px;
        }
    }
    @keyframes shakeX {
        from, to {
            transform: translate3d(0, 0, 0);
        }

        10%, 30%, 50%, 70%, 90% {
            transform: translate3d(-5px, 0, 0);
        }

        20%, 40%, 60%, 80% {
            transform: translate3d(5px, 0, 0);
        }
    }
    .term-service--shake {
        animation: .2s shakeX;
    }
</style>
